/* You can add global styles to this file, and also import other style files */
@import 'libs/shared/src/lib/utils/utils';
@import 'libs/shared/src/lib/utils/fonts';
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Artegra', sans-serif;
}

h1,
h2,
h3 {
  font-family: 'Artegra-Bold';
}

.primary-color {
  color: #443193 !important;
}

.accent-color {
  color: #000000;
}

.warning-color {
  color: #ffcd1c;
}

.error-color {
  color: #fc4e69 !important;
}

.success-color {
  color: #389e0d;
}

.white-color {
  color: #fff !important;
}

p {
  b,
  strong {
    font-family: 'Artegra-Bold';
  }

  a {
    font-family: 'Artegra-Semibold';
  }
}

.defaultModal .ant-modal-content {
  min-height: 100vh;
}

// To onboarding modal.
.ant-notification {
  z-index: 999;
}

.cdk-virtual-scrollable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #f0f2f5 !important;
  border: 1px solid #f0f2f5 !important;
  border-radius: 8px !important;
}

.cdk-virtual-scrollable::-webkit-scrollbar {
  width: 5px !important;
}

.cdk-virtual-scrollable::-webkit-scrollbar-thumb {
  background: #6d54fc !important;
  border-radius: 8px !important;
}

.desktop {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.mobile {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.ant-rate-star-full {
  .ant-rate-star-first .anticon,
  .ant-rate-star-second .anticon {
    color: #ffce1c;
  }
}
